@import url(https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* * {
  font-family:"Roboto","Open Sans","ui-sans-serif", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */




.react-datepicker-wrapper {
  width: 100%
}

.PhoneInputInput {
  background-color: var(--dark-gray);
}



@media (max-width: 700px) {

  .dashboardStackDesktop {
    display:none !important
  }

  .dashboardStackMobile {
    display:flex !important;
    flex-wrap: wrap;
    justify-content: 'space-evenly' !important;
    grid-gap:6px !important;
    gap:6px !important

  }

  .addTradeIndexerMobile {
    display:inline-flex !important;
    flex-wrap: wrap;
    justify-content: 'space-between' !important;
    width: 100%;
  }
}

.NiceBox_container__1ouw3 {
    max-width:90vw;
    margin:1rem 1rem 1rem 1rem;
    border-radius: 5px;
    padding:1rem 2rem 2rem 2rem;
    justify-content: center;
    align-items: left;
    text-align: left;

    background-color:white;
    /* box-shadow: 0 0 0 1px rgba(14,41,57,.12), 0 2px 5px rgba(14,41,57,.44), inset 0 -1px 2px rgba(14,41,57,.15); */
    border:1px solid lightgrey;
    border-radius: 4px;
}
.NiceButton_customLink__CwsHT {
    background-color: dodgerblue;
    color:white;
    font-size:1rem;
    cursor: pointer;

    box-sizing: border-box;
    text-align: center;
    padding: 10px 40px 10px 40px;
    border-radius: 6px;
    -ms-box-shadow: 0 1px 1px rgba(0,0,0,.16);
    -o-box-shadow: 0 1px 1px rgba(0,0,0,.16);
    box-shadow: 0 1px 1px rgba(0,0,0,.16);

    text-decoration: none;

    margin-top:1rem;
    outline: none;


    background-color: black;
    color:white;
    font-size:1rem;
    text-align: center;
    margin-top:1.5rem;
    cursor: pointer;
    font-weight:700;

    box-sizing: border-box;
    text-align: center;
    height: 44px;
    border-radius: 4px;
    border:0px solid;
}
.NiceInput_customInput__2AnZd {
    text-align: left;
    margin-top:0.2rem;
    margin-bottom:0.3rem;

    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 12px;
    width: 100%;
    border-radius: 5px;
  
    -webkit-font-smoothing: antialiased;
    border: 1px solid lightgrey;
    border-top-color: lightgrey;
    font-size: 18px;


    text-align: left;
    margin-top:0.2rem;
    margin-bottom:0.3rem;

    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 12px;
    width: 100%;
    border-radius: 8px;
  
    -webkit-font-smoothing: antialiased;
    border: 1px solid white;
    border-top-color: white;
    font-size: 18px;
    background-color: #f2f4f7;

    
}

.NiceModal_modal__2QJ6z {
    outline: none;
    position:absolute;
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    margin-top:2rem;
    width:300px;
    text-align: center;
    background-color: white;
    padding:1rem 1rem 2rem 1rem;
    box-shadow: 0 0 0 1px rgba(14,41,57,.12), 0 2px 5px rgba(14,41,57,.44), inset 0 -1px 2px rgba(14,41,57,.15);

}
.NiceLink_customLink__3P72P {
    background-color: white;
    color:grey;
    font-size:0.8rem;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    text-align: center;
    padding: 5px 10px 5px 10px;
    border-radius: 6px;
    -ms-box-shadow: 0 1px 1px rgba(0,0,0,.16);
    -o-box-shadow: 0 1px 1px rgba(0,0,0,.16);
    box-shadow: 0 1px 1px rgba(0,0,0,.16);
    text-decoration: none;
    outline: none;
    margin-left:0.3rem;
    margin-right:0.3rem;
    border:1px solid lightgrey;
}
.TableHeader_tableHeaderX__2csje {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    background: var(--dark-gray);
    z-index: 1 !important;
}
.customButton {
    text-align: center;
    -webkit-appearance: none;
    /* turn off drop-down arrow*/
    -moz-appearance: none;
    border: solid 1px grey;
    font-size: 1rem;

    /* direction: rtl; */
    text-align: center;
    -webkit-appearance: none;
    /* turn off drop-down arrow*/
    -moz-appearance: none;
    border: solid 1px grey;
    border-color: hsl(0, 0%, 58%);

    font-size: 1rem;
    padding-left: 25px;
    /* direction: rtl; */
}

.react-daterange-picker__wrapper {
    border-radius: 4px;
    margin-left: 5px;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 58%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
}

.react-daterange-picker__calendar-button {
    display: none;
}

.react-daterange-picker__clear-button__icon react-daterange-picker__button__icon {
    color: black;
}

.selectPicker {
    margin-left: 5px;
}

.selectPicker div {
    border-color: hsl(0, 0%, 58%);

}

.react-date-picker__inputGroup__divider {
    color: lightgrey;
}

.react-daterange-picker__range-divider {
    color: grey;
}

.react-resizable-handle::after {
    height: 10px !important;
    width: 10px !important;
    border-bottom-right-radius: 5px;
}

.react-resizable-handle {
    height: 10px !important;
    width: 10px !important;
    border-bottom-right-radius: 5px;
    background-image: none;
}


.customDateRangePickerDark .react-daterange-picker__wrapper {
    background-color: #313640;
    color: white;
    border: 0px solid rgb(232, 232, 232);
}

.customDateRangePickerDarker .react-daterange-picker__wrapper {
    background-color: var(--bg);
    color: white;
    border: 0px solid rgb(232, 232, 232);
}

.customDateRangePickerLight .react-daterange-picker__wrapper {
    background-color: white;
    color: #313640;
    border: 2px solid rgb(232, 232, 232);
}

.customDateRangePickerLight .react-daterange-picker__clear-button {
    color: white;
}

.customDateRangePickerDark .react-daterange-picker__clear-button {
    -webkit-filter: invert(100%);
            filter: invert(100%);

}

.react-calendar {
    color: black;
}

.accountIcon {
    border-radius: 32px !important;
    background-color: var(--dark-gray) !important;
}

.accountIcon:hover {
    background-color: var(--accent) !important;
}

.accountIcon:hover svg {
    color: var(--white) !important;
}


.accountIcon svg {
    color: var(--light-gray);
    width: 20px;
    height: 20px;
}

.dashboard-top-menu {
    /* justify-content: space-between; */
}

.filterPanel {
    background: var(--dark-gray);
    border-radius: 12px;
    padding: 20px;

    /* 
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    justify-content: flex-start; */
}

/* .menuOpen {}

.menuClosed {
    max-width: 70vw;
} */

.ag-menu {
    z-index: 99999999 !important;
}

.ag-cell[col-id="tradeType"] {
    display: flex;
    justify-content: center;
    align-items: center;
  }
.ag-cell[col-id="positionStatus"] {
    display: flex;
    justify-content: center;
    align-items: center;
  }
.ag-cell[col-id="action"] {
    display: flex;
    justify-content: center;
    align-items: center;
    margin:auto !important; 
  }


  .addInsightWidgetDashboard_1 {
    transition: all 0.2s ease-in-out;
  }

  .addInsightWidgetDashboard_1:hover {
    text-shadow: 0px 0px 30px var(--white);
  }

.drag-handle {
    /* position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    text-align: center;
    width:100px; */
    cursor: grab;
}

.dark-handle {
    color: #718096;
}

.light-handle {
    color: #A0AEC0;

}

.right-handle {
    margin-right: 0;
}
[stroke="url(#someGradientId)"] {
    stroke: #ff0000;
  }
.customTableHeader {
    height: 50px;
}

#droppable {
    /* background: #e2e8f0 !important; */

}

tr {
    /* background: white; */
    /* border-top: 2px solid transparent; */
    /* border-bottom: 2px solid transparent; */

    transition: all .2s ease-in-out;

}


.lightRow:hover {
    background-color: #E2E8F0;
    /* border-top: 2px solid grey; */
    /* border-bottom: 2px solid grey; */
}

.darkRow:hover {
    background-color: #171923;
    /* border-top: 2px solid grey; */
    /* border-bottom: 2px solid grey; */
}



.whiteBackground {
    /* background: white !important; */
    /* border-right:solid lightgrey 1px; */
}



.customButton {
    text-align: center;
    -webkit-appearance: none;
    /* turn off drop-down arrow*/
    -moz-appearance: none;
    border: solid 1px grey;
    font-size: 1rem;

    /* direction: rtl; */
    text-align: center;
    -webkit-appearance: none;
    /* turn off drop-down arrow*/
    -moz-appearance: none;
    border: solid 1px grey;
    border-color: hsl(0, 0%, 58%);

    font-size: 1rem;
    padding-left: 25px;
    /* direction: rtl; */
}

.react-daterange-picker__wrapper {
    border-radius: 4px;
    margin-left: 5px;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 58%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
}

.react-daterange-picker__calendar-button {
    display: none;
}

.react-daterange-picker__clear-button__icon react-daterange-picker__button__icon {
    color: black;
}

.selectPicker {
    margin-left: 5px;
}

.selectPicker div {
    border-color: hsl(0, 0%, 58%);

}

.react-date-picker__inputGroup__divider {
    color: lightgrey;
}

.react-daterange-picker__range-divider {
    color: grey;
}

.react-resizable-handle::after {
    height: 10px !important;
    width: 10px !important;
    border-bottom-right-radius: 5px;
}

.react-resizable-handle {
    height: 10px !important;
    width: 10px !important;
    border-bottom-right-radius: 5px;
    background-image: none;
}


.customDateRangePickerDark .react-daterange-picker__wrapper {
    background-color: #313640;
    color: white;
    border: 0px solid rgb(232, 232, 232);
}

.customDateRangePickerLight .react-daterange-picker__wrapper {
    background-color: white;
    color: #313640;
    border: 2px solid rgb(232, 232, 232);
}

.customDateRangePickerLight .react-daterange-picker__clear-button {
    color: white;
}

.customDateRangePickerDark .react-daterange-picker__clear-button {
    -webkit-filter: invert(100%);
            filter: invert(100%);

}

.react-calendar {
    color: black;
}







.darkStickyLeft {
    background-color: #2D3748;

}

.lightStickyLeft {

    background-color: white;
}

.ioCircleIcon {
    fill: var(--white) !important;
}

.ioCircleIconAccent {
    stroke: var(--accent) !important;
}

.uploadAddTradeSearchRow:hover {
    background-color: var(--gray);
    cursor: pointer;
}

.drop-zone {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.tableHeaderDrag {}

.stickyTable {
    overflow: scroll;
    position: -webkit-sticky;
    position: sticky;
}

.layerZero {
    /* flex-wrap: wrap; */
}

.ag-pivot-mode-panel {
    display: none !important;
}

.ag-last-column-drop {
    display: none !important;
}

.ag-root-wrapper {
    background-color: transparent !important;

    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;

}


.ag-column-drop-horizontal,
.ag-side-buttons {
    background-color: var(--dark-gray) !important;

}

.ag-header {
    background-color: var(--dark-gray) !important;

}

.ag-row {
    background-color: var(--bg) !important;
}

.ag-row-odd {
    background-color: var(--dark-gray) !important;
}

.ag-tool-panel-wrapper {
    background-color: var(--dark-gray) !important;
}


.ag-radio-button-input-wrapper {
    font-family: var(--ag-icon-font-family) !important;
}

.ag-popup-child {
    z-index: 1000 !important;
}
.darkAutoComplete  li:hover {
    background-color: #2D3748; 

}

.lightAutoComplete  li:hover {
    background-color: #E2E8F0; 

}
.chakra-tabs__tab { 
    /* color:#b1bccf !important; */
}
.AddTrade_form__2rOQy {
  display: flex;
  flex-direction: column;
  max-width: 200px;
}

.AddTrade_react-datepicker-wrapper__3SByw {
  width: 100%
}



.AddTrade_overlayOptionDataRetrieval__3yZO4 {
  position: absolute;
  left: 0px;
  top: -0px;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 99999999;
  opacity: 0.7;
  border-radius: 5px;
  transition: 0.4s all;
  padding-top: 20px;
}

.AddTrade_react-datepicker__5OdQw {
  background-color: red;
  width: 100%;
  min-width: 0px;
  outline: 0;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  transition: all 0.2s;
  font-size: var(--chakra-fontSizes-md);
  -webkit-padding-start: var(--chakra-space-4);
  padding-inline-start: var(--chakra-space-4);
  -webkit-padding-end: var(--chakra-space-4);
  padding-inline-end: var(--chakra-space-4);
  height: var(--chakra-sizes-10);
  border-radius: var(--chakra-radii-md);
  border: 1px solid;
  border-color: inherit;
  background: inherit;

  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
  overflow: hidden;
}

.AddTrade_react-datepicker__navigation--next--with-time__Rr-_G:not(.AddTrade_react-datepicker__navigation--next--with-today-button__yhPNa) {
  right: 90px;
}

.AddTrade_react-datepicker__navigation--previous__2fUkA,
.AddTrade_react-datepicker__navigation--next__37Nk_ {
  height: 8px;
}

.AddTrade_react-datepicker__navigation--previous__2fUkA {
  border-right-color: #cbd5e0;

  &:hover {
    border-right-color: #a0aec0;
  }
}

.AddTrade_react-datepicker__navigation--next__37Nk_ {
  border-left-color: #cbd5e0;

  &:hover {
    border-left-color: #a0aec0;
  }
}

.AddTrade_react-datepicker-wrapper__3SByw,
.AddTrade_react-datepicker__input-container__2uHwQ {
  display: block;
}

.AddTrade_react-datepicker__header__BWW9N {
  border-radius: 0;
  background: #f7fafc;
}

.AddTrade_react-datepicker__5OdQw,
.AddTrade_react-datepicker__header__BWW9N,
.AddTrade_react-datepicker__time-container__VxZRn {
  /* border-color: #e2e8f0; */
}

.AddTrade_react-datepicker__current-month__32hk2,
.AddTrade_react-datepicker-time__header__2zy29,
.AddTrade_react-datepicker-year-header__21gp4 {
  font-size: inherit;
  font-weight: 600;
}

.AddTrade_react-datepicker__time-container__VxZRn .AddTrade_react-datepicker__time__3vPSt .AddTrade_react-datepicker__time-box__2ZhIj ul.AddTrade_react-datepicker__time-list__1JDcg li.AddTrade_react-datepicker__time-list-item__2pMyS {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;

  &:hover {
    background: #edf2f7;
  }
}

.AddTrade_react-datepicker__day__3O7Qm:hover {
  background: #edf2f7;
}

.AddTrade_react-datepicker__day--selected__2xnKa,
.AddTrade_react-datepicker__day--in-selecting-range__2_3MP,
.AddTrade_react-datepicker__day--in-range__2sdP1,
.AddTrade_react-datepicker__month-text--selected__LdvKh,
.AddTrade_react-datepicker__month-text--in-selecting-range__3nhDK,
.AddTrade_react-datepicker__month-text--in-range__3D82u,
.AddTrade_react-datepicker__time-container__VxZRn .AddTrade_react-datepicker__time__3vPSt .AddTrade_react-datepicker__time-box__2ZhIj ul.AddTrade_react-datepicker__time-list__1JDcg li.AddTrade_react-datepicker__time-list-item--selected__5G4uN {
  background: #3182ce;
  font-weight: normal;

  &:hover {
    background: #2a69ac;
  }
}
#multi-leg-entry-table td {
    padding: 1px;
}

#multi-leg-entry-table .chakra-select__icon-wrapper {
    display: none;
}

#multi-leg-entry-table .chakra-select {
    padding: 1px;
    text-align: center;
}

.react-datepicker-multi-leg {

    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-normal);
    font-size: var(--chakra-fontSizes-xs);
    -webkit-padding-start: var(--chakra-space-2);
            padding-inline-start: var(--chakra-space-2);
    -webkit-padding-end: var(--chakra-space-2);
            padding-inline-end: var(--chakra-space-2);
    height: var(--chakra-sizes-6);
    border-radius: var(--chakra-radii-sm);
    border: 1px solid;
    background: inherit;
    border-color: var(--chakra-colors-gray-300);



}


#multi-leg-entry-table {

    margin-top: 20px;
    margin-bottom: 20px;
}

.customDeleteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    width: 35px !important;
    height: 35px !important;
}

.customDeleteButton span {
    fill: var(--red);
    padding: 0;
    margin: 0;
}
.TagView_dropdown__1y4J8 {
    width: 100%;

}

.TagView_dropdown__1y4J8 svg {
    stroke-width: 2px;
    stroke: white;
    fill: transparent;
    width: 20px;
    height: 20px;

}

.TagView_darkDropdown__7casB {
    /* min-width: 200px; */
    width: 100%;

}

.TagView_newTagDropdown__yZmJy {
    background-color: red !important;
}
.editableInput {
    min-width: 70px;

}

.editablePreview {
    min-width: 70px;
    overflow: hidden;
    white-space: nowrap;
    /* Don't forget this one */
    text-overflow: ellipsis;
    padding-left: -4px;


    max-width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    color: var(--light-gray);
    background-color: var(--dark-gray);
    border-radius: 6px;
    border: transparent;
    padding: 6px;

    /* z-index: 7; */
}

.editableDropDown {
    width: 100%;
}

.editableDropDown input {
    color: var(--white) !important
}

.newTableDropDown {
    background-color: transparent !important;

    justify-self: flex-start !important;
    padding: 0 !important;
    margin: 0 !important;
    align-self: baseline !important;
    text-decoration: underline !important;

    width: 100%;


    height: 39px !important;
    overflow: hidden !important;
}

.newTableDropDown div {
    background-color: transparent !important;

}

.newTableDropDown div div {
    background-color: transparent !important;

    padding: 0px;
    text-align: center;
    max-width: 140px;
    color: var(--white) !important;

}

.newTableDropDownNoValue div div {
    text-decoration: underline !important;
    color: var(--accent) !important;

}

.newTagDropdown {
    background-color: transparent !important;
    justify-self: flex-start !important;
    padding: 0 !important;
    margin: 0 !important;
    align-self: baseline !important;
    height: 39px !important;
    overflow: hidden !important;
}

.tradeSourceDropdown {
    height: 100% !important;
    width: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 !important;
    text-decoration: underline !important;

}

.tradeSourceDropdownNoData {
    text-decoration: underline !important;
    color: var(--accent) !important;
}

div[col-id="tradeSource"] {
    padding: 0 !important;
}

div[col-id="tags"] {
    padding: 0 !important;
}
.highlightLink {

    cursor: pointer;
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 2px;
    padding-top: 4px;
    padding-bottom: 4px;
    min-width: 100% !important;
    font-size: small;
}

.highlightLink:hover {
    background-color: var(--accent);
}

.highlightLinkTextOnly {
    padding-left: 4px;
    padding-right: 4px;
    border-radius: 2px;
    padding-top: 4px;
    padding-bottom: 4px;
    min-width: 100% !important;
    font-size: 10px;
}
.Trades_lightButton__1sCyS {
    background-color: dodgerblue;
    border-radius: 30px;
    color: white;
    font-weight: 300;
    text-decoration: none;
    outline: none;
    width: 300px;
    padding: 1rem 1rem 1rem 1rem;


}

.Trades_dropDownGrouping__siYjD {

    width: 300px;
    justify-self: center;
    align-self: center;
}

.Trades_lightButton__1sCyS:hover {
    background-color: white;
    border-radius: 30px;
    border: 1px solid grey;
    color: dodgerblue;


}

.Trades_websiteContainer__8rxGW {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgrey;
    width: 80vw;
    /* padding:5px; */
    /* border-radius: 5px; */
}

.Trades_topRow__3cEkP {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
}

.Trades_websiteTitle__31Hfz {
    flex: 2 1;
    font-weight: 400;
    padding: 0;
}


.Trades_domUrlCon__2I8u- {
    color: white;
    background-color: dodgerblue;
    text-align: center;
    padding: 0.2rem 1rem 0.2rem 1rem;
    margin-right: 1rem;
    border-radius: 50px;
    border: 1px solid white;

}

.Trades_domUrlCon__2I8u-:hover {
    color: dodgerblue;
    border: 1px solid dodgerblue;
    background-color: white;
}

.Trades_domUrla__RYGMX {
    text-decoration: none;
    color: white;
}

.Trades_domUrla__RYGMX:hover {
    color: dodgerblue;
}

.Trades_nameserversContainer__3vquK {
    /* border: solid black 1px; */
    display: flex;
    flex-direction: row;
    margin-left: 1rem;
    margin-top: 1rem;
}

.Trades_nameserver__1QG4n {
    flex: 1 1;
    height: 1.5rem;
    text-align: center;
    border-radius: 20px;
    border: 1px solid lightgrey;
    margin: 0 1rem 0 1rem;
    outline: none;
}

.Trades_websiteContainerTitle__5njR9 {
    text-align: left;
}

.Trades_dnsRec__2lTcL {
    color: grey;
    padding: 0 1rem 0 1rem;
}


.Trades_metricsContainer__15niH {
    display: flex;
    flex-direction: row;
    margin: 1rem 0 1rem 0;
}

.Trades_metric__2Mq7v {
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1 1;
    display: flex;
    flex-direction: row;
    color: grey;
    font-size: 0.9rem;
}

.Trades_metricValue__3A-jd {
    text-align: center;
    margin-left: 1rem;
    color: black;
    font-size: 1.2rem;
}

.Trades_metricLabel__3-p2S {
    text-align: center;
    font-size: 1.2rem;

}


.Trades_modal__3W-F2 {
    width: 80vw;
    margin: auto;
    background-color: white;
    border: solid black 1px;
    padding: 0 1rem 1rem 1rem;
    margin-top: 5rem;
}

.Trades_settings__3N9wJ {
    justify-content: center;
    align-items: center;
    flex: 1 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}


.Trades_deleteButton__14eN5 {
    flex: 1 1;
    text-align: center;
    text-align: center;
    background-color: red;
    border-radius: 30px;
    color: white;
    font-weight: 300;
    text-decoration: none;
    outline: none;
    width: 300px;
    padding: 1rem 1rem 1rem 1rem;
    border: 0px solid grey;
}

.Trades_deleteButton__14eN5:hover {
    background-color: white;
    border-radius: 30px;
    border: 1px solid grey;
    color: red;


}

.Trades_lightButtonSettings__3my4H {
    flex: 1 1;
    text-align: center;
    text-align: center;
    background-color: dodgerblue;
    border-radius: 30px;
    color: white;
    font-weight: 300;
    text-decoration: none;
    outline: none;
    width: 300px;
    padding: 1rem 1rem 1rem 1rem;
    border: 0px solid grey;
}

.Trades_lightButtonSettings__3my4H:hover {
    background-color: white;
    border-radius: 30px;
    border: 1px solid grey;
    color: dodgerblue;


}

.Trades_welcome__3QE5D {
    text-align: left;
}

.Trades_description__1hrSh {
    margin-top: 2rem;
    margin-bottom: 2.5rem;
}

.Trades_narrow__2nfZZ {
    max-width: 45vw;
}

.Trades_padder__2FRau {
    height: 2rem;
}


.Trades_WebsiteBoxContainer__1Ls2V {
    width: 90wv;
}

.Trades_Nicebox__Vi-Id {
    max-width: 90vw;
    min-width: 90vw;
    margin: 1rem 1rem 1rem 1rem;
    border-radius: 5px;
    padding: 6rem 2rem 10rem 2rem;
    justify-content: center;
    align-items: center;
    text-align: center;

    background-color: white;
    box-shadow: 0 0 0 1px rgba(14, 41, 57, .12), 0 2px 5px rgba(14, 41, 57, .44), inset 0 -1px 2px rgba(14, 41, 57, .15);
    border-radius: 4px;
}

.Trades_NiceboxWebsiteContainer__2Cx-l {
    max-width: 90vw;
    min-width: 90vw;
    margin: 1rem;
    border-radius: 5px;
    padding: 0rem 1rem 1rem 1rem;
    justify-content: center;
    align-items: center;
    text-align: left;

    background-color: white;
    box-shadow: 0 0 0 1px rgba(14, 41, 57, .12), 0 2px 5px rgba(14, 41, 57, .44), inset 0 -1px 2px rgba(14, 41, 57, .15);
    border-radius: 4px;
}

.Trades_NoWebsites__2I7vL {
    text-align: center;
    margin-bottom: 2rem;
    color: grey;
    font-weight: 500;
}

.Trades_icon__Y33Hk {
    font-size: 4rem;
    color: grey;
}

.Trades_SmallPlusIcon__32pvE {
    padding-right: 1rem;
}

.Trades_domainSpan__49rEy {
    color: lightgrey;
    padding-left: 2rem;
}

.Trades_date__NPC8M {
    padding-right: 2rem;
    color: lightgrey;

}

.Trades_from__3c9yR {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    color: grey;

}


/**
* ----------------------------------------------
* Accordian styles
* ----------------------------------------------
**/
.Trades_accordion__3q1Ie {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
}

.Trades_accordion__item__1Smqz+.Trades_accordion__item__1Smqz {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.Trades_accordion__button__hxRdY {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 8px;
    text-align: left;
    border: none;
}

.Trades_accordion__button__hxRdY:hover {
    background-color: #ddd;
}

.Trades_accordion__button__hxRdY:before {
    display: inline-block;
    content: '';
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.Trades_accordion__button__hxRdY[aria-expanded='true']::before,
.Trades_accordion__button__hxRdY[aria-selected='true']::before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.Trades_accordion__panel__15Sk_ {
    padding: 20px;
    -webkit-animation: Trades_fadein__2LsJ8 0.35s ease-in;
            animation: Trades_fadein__2LsJ8 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@-webkit-keyframes Trades_fadein__2LsJ8 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes Trades_fadein__2LsJ8 {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}



.Trades_filterMenu__3IWwR {
    display: flex;
    flex-direction: row;
}

.Trades_form__1fuaf {
    display: flex;
    flex-direction: column;
    max-width: 200px;
}

.Trades_react-datepicker-wrapper__3g3IC {
    width: 100%
}

.Trades_react-datepicker__A9BfZ {
    background-color: red;
    width: 100%;
    min-width: 0px;
    outline: 0;
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    transition: all 0.2s;
    font-size: var(--chakra-fontSizes-md);
    -webkit-padding-start: var(--chakra-space-4);
    padding-inline-start: var(--chakra-space-4);
    -webkit-padding-end: var(--chakra-space-4);
    padding-inline-end: var(--chakra-space-4);
    height: var(--chakra-sizes-10);
    border-radius: var(--chakra-radii-md);
    border: 1px solid;
    border-color: inherit;
    background: inherit;

    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
    overflow: hidden;
}

.Trades_react-datepicker__navigation--next--with-time__Pn7J8:not(.Trades_react-datepicker__navigation--next--with-today-button__1E3Cg) {
    right: 90px;
}

.Trades_react-datepicker__navigation--previous__1dhwQ,
.Trades_react-datepicker__navigation--next__1iakx {
    height: 8px;
}

.Trades_react-datepicker__navigation--previous__1dhwQ {
    border-right-color: #cbd5e0;

    &:hover {
        border-right-color: #a0aec0;
    }
}

.Trades_react-datepicker__navigation--next__1iakx {
    border-left-color: #cbd5e0;

    &:hover {
        border-left-color: #a0aec0;
    }
}

.Trades_react-datepicker-wrapper__3g3IC,
.Trades_react-datepicker__input-container__2oeOs {
    display: block;
}

.Trades_react-datepicker__header__q89J6 {
    border-radius: 0;
    background: #f7fafc;
}

.Trades_react-datepicker__A9BfZ,
.Trades_react-datepicker__header__q89J6,
.Trades_react-datepicker__time-container__V_rrd {
    /* border-color: #e2e8f0; */
}

.Trades_react-datepicker__current-month__2R2CI,
.Trades_react-datepicker-time__header__1oZXU,
.Trades_react-datepicker-year-header__3_6qS {
    font-size: inherit;
    font-weight: 600;
}

.Trades_react-datepicker__time-container__V_rrd .Trades_react-datepicker__time__2Vj7q .Trades_react-datepicker__time-box__2pWmR ul.Trades_react-datepicker__time-list__PUOB- li.Trades_react-datepicker__time-list-item__1ae7R {
    margin: 0 1px 0 0;
    height: auto;
    padding: 7px 10px;

    &:hover {
        background: #edf2f7;
    }
}

.Trades_react-datepicker__day__DL3SW:hover {
    /* background: #edf2f7; */
}

.Trades_react-datepicker__day--selected__3hJoG,
.Trades_react-datepicker__day--in-selecting-range__1IM8i,
.Trades_react-datepicker__day--in-range__3lpby,
.Trades_react-datepicker__month-text--selected__WdBtC,
.Trades_react-datepicker__month-text--in-selecting-range__17OVy,
.Trades_react-datepicker__month-text--in-range__3Rfc8,
.Trades_react-datepicker__time-container__V_rrd .Trades_react-datepicker__time__2Vj7q .Trades_react-datepicker__time-box__2pWmR ul.Trades_react-datepicker__time-list__PUOB- li.Trades_react-datepicker__time-list-item--selected__3ocC6 {
    background: #3182ce;
    font-weight: normal;

    &:hover {
        background: #2a69ac;
    }
}


.Trades_tableRow__Io6qQ {}

.Trades_groupedRowLight__2AFl8 {
    /* border-bottom: 2px rgb(185, 185, 185) solid;; */
    font-weight: 600;

    background-color: #cfd9e4;

}



.Trades_groupedRowAltLight__2Y1ax {
    font-weight: 500;

    background-color: #E2E8F0;

}

.Trades_nonGroupedRowLight__2BFni {
    transition: all 0.1s;
    cursor: grab;


    background-color: #EDF2F7;
}

.Trades_nonGroupedRow__2mgN_:hover+.Trades_hiddenRow__29pc- {
    display: block;
}

.Trades_groupedRowDark__3EJS- {
    /* border-bottom: 2px #1A202C solid;; */
    font-weight: 600;
    background-color: #2D3748;

}





.Trades_nonGroupedRowDark__2uJ6J {
    transition: all 0.1s;
    cursor: grab;
    background-color: #4e5d74;
}

.Trades_standardRowDark__3GNps {
    transition: all 0.1s;
    cursor: grab;
}

.Trades_standardRowLight__292lr {
    transition: all 0.1s;
    cursor: grab;
}

.Trades_nonGroupedRow__2mgN_:hover+.Trades_hiddenRow__29pc- {
    display: block;
}


.Trades_hiddenRow__29pc- {
    position: relative;
    top: -30px;
    left: -20px;
    height: 0px;
    overflow: visible;
    display: none;
}

.Trades_nonGroupedRow__2mgN_:hover,
.Trades_groupedRow__3kVbn:hover,
.Trades_groupedRowAlt__FdBzT:hover {
    /* background: rgb(240, 240, 240) !important;  */

}


td[role=cell] {
    padding: 0px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    border-color: transparent;
}


.Trades_customTopRow__3gUsC {
    background-color: var(--bg);
}

.Trades_customRootRow__19aRd {
    background-color: var(--bg);
}

.Trades_customTableRow__wO4Cu {
    border-bottom: 1.5px solid var(--gray);
}

.Trades_customTableRow__wO4Cu:hover {
    background-color: var(--gray) !important;
}



.Trades_roundedCorners__1ul6L:nth-child(1) {
    border-top-left-radius: 6px !important;
}

.Trades_roundedCorners__1ul6L {
    /* background-color: var(--bg) !important; */
    /* border-bottom: 1px var(--light-gray) solid !important; */
    /* padding-top: 6px !important; */
    /* padding-bottom: 6px !important; */
}


.Trades_roundedCorners__1ul6L:nth-last-child(1) {
    border-top-right-radius: 6px !important;


}

.Trades_customLastRow__3eBGW:nth-child(1) {
    border-bottom-left-radius: 6px !important;

}

.Trades_customLastRow__3eBGW:nth-last-child(1) {
    border-top-right-radius: 6px !important;
}


.Trades_customTableRow__wO4Cu {
    /* background-color: red !important; */
    /* border: 2px solid transparent; */
    padding: 0px !important;
}

.Trades_customTableRow__wO4Cu {}




.Trades_firstGroupingTd__11g7z {
    /* background-color: var(--light-gray) !important; */
    border-bottom: 1px solid var(--light-gray) !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;

}

.Trades_firstGroupingRow__1u1Cg {
    border-left: 4px solid var(--accent);

}

.Trades_subGroupedRow__V7ylS {
    border-left: 4px solid orange;

    background-color: var(--bg);
    background: repeating-linear-gradient(45deg, var(--bg), var(--bg) 6px, var(--dark-gray) 9px, var(--dark-gray) 9px);
}

.Trades_zeroGroupingRow__1Vyyv {
    /* border-left: 4px solid var(--red) !important; */

}

.Trades_strategyCell__3u3uE {
    /* background-color: var(--dark-gray) !important;
    background-color: var(--dark-gray) !important;

    position: absolute; */

    min-width: 180px;

}



.Trades_customTableRow__wO4Cu:hover {
    /* -webkit-box-shadow: inset 0px 0px 0px 1px var(--accent) !important;
    -moz-box-shadow: inset 0px 0px 0px 1px var(--accent) !important;
    box-shadow: inset 0px 0px 0px 1px var(--accent) !important;
    background-color: var(--accent); */
}

.Trades_actionsCell__1H3RW {
    width: 40px;

}

.Trades_columnChangerOverlay__2qazA {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 2;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Trades_tradeTableContainerExtended__1-S1r {
    width: 100%;
    max-height: calc(85vh) !important;
    max-width: calc(100vw - 330px) !important;
    /* min-height: calc(85vh) !important; */

    overflow: scroll;
}

.Trades_tradeTableContainer__2e4vI {
    width: 100%;
    max-height: calc(85vh) !important;
    /* min-height: calc(85vh) !important; */
    max-width: calc(100vw - 140px) !important;
    overflow: scroll;
}


@media only screen and (max-width: 800px) {




    .Trades_allOpenClosed__jIpft {
        display: none;
        ;
    }
}

@media only screen and (max-width: 600px) {

    .Trades_dropDownGrouping__siYjD {
        display: none;
    }

    .Trades_pageNumber__1Tc-n {
        display: none;
    }


}

.Trades_stockTicker__1yf6o {
    background-color: var(--dark-gray);
    min-width: 80px;
    height: 100% !important;

    border: 1px solid var(--dark-gray);

}

.Trades_stockTicker2__20Px8 {
    min-width: 80px;
    height: 100% !important;


}


.Trades_stockTickerCell__2oEMi {
    /* background-color: var(--dark-gray) !important; */
    margin: 0 !important;
    z-index: 0 !important;
    /* transform: translateX(-2px) scale(1.02); */
    height: 45px;
}

.Trades_chevron__1Q_-Y {
    height: 100% !important;
    /* background-color: var(--dark-gray); */
}

/* minW={'80px'} spacing='12px' bg="var(--dark-gray)" */


.Trades_removeCircle__2Xm5T {
    margin-right: 16px;
    width: 24px;
    height: 24px;
    fill: var(--white);
    color: var(--white);
}


.Trades_addCircle__1G5tw {
    margin-right: 16px;
    width: 24px;
    height: 24px;
    fill: var(--light-gray);
    color: var(--light-gray);
}
.chartLight {

}

.chartDark {
    
}
.row td[role=cell] {
    padding: 0px;
    margin: 0px;
    line-height: 30px;
    text-align: center;
}
   


.row {
    transition: all 0.1s;
    border:black 0px solid;

}

.row:hover {
    background: rgb(240, 240, 240);
}

.cell {
    transition: all 0.1s;
    /* border:transparent 2px solid; */
    cursor:crosshair;
}

.cell:hover {
    background: rgb(240, 240, 240);

}

.TVChartContainer {
    height: 500px;
    padding-top: 20px;
}
.strongBuyValue,
.buyValue,
.holdValue,
.sellValue,
.strongSellValue {
    background-color: var(--bg) !important;
}

.strongBuyValue div {
    background: linear-gradient(270.01deg, var(--accent) 0.01%, rgba(38, 195, 99, 0) 101.01%) !important;

}

.buyValue div {
    background: linear-gradient(270.01deg, #26C363 0.01%, rgba(38, 195, 99, 0) 101.01%) !important;

}


.holdValue div {
    background: linear-gradient(270deg, #A9DFD8 0%, rgba(169, 223, 216, 0) 100%);

}

.sellValue div {
    background: linear-gradient(270deg, #FEB95A 0%, rgba(254, 185, 90, 0) 100%);

}

.strongSellValue div {
    background: linear-gradient(270deg, #EA5848 0%, rgba(234, 88, 72, 0) 100%);
}


.institutionProgress div {
    background: var(--accent);
    border-right: 2px solid var(--white);

}

.insiderProgress div {
    background: var(--chart-baby-blue);
    border-right: 2px solid var(--white);
}

.otherProgress div {
    background: var(--chart-orange);
    border-right: 2px solid var(--white);

}

.fundProgress div {
    background: var(--chart-dark-blue);
    border-right: 2px solid var(--white);
}



.PoolContainer_waveContainer__3XpMz {
    position: relative;
 
    min-width:200px;
    max-width:200px;
    min-height:200px;
    max-height:200px;
    
    display: flex;
    justify-content: center;
    align-items: center;


    padding: 0;
    margin: 0;

    border: solid 2px var(--gray);

}

.PoolContainer_wave__oXZDf {
    position: absolute  ;
    z-index: 2;
    border-radius: 100%;

    width:250px;
    height:200px;
}

.PoolContainer_wave__oXZDf svg {
    border-radius: 100%;

}

.PoolContainer_wave2__3Omwk {
    position: absolute  ;
    z-index: 2;

    width:250px;
    height:200px;
}

.PoolContainer_wave2__3Omwk svg {
    border-radius: 100%;



}

.PoolContainer_label__2UeKP {
    position: absolute;
    text-align: center;
    z-index: 3;
    color: var(--white);
    text-shadow: 0px 0px 4px var(--bg);
}

.react-daterange-picker {
    width: 100%;
}

.react-daterange-picker__wrapper {
    justify-content: space-around;
    width: 257px;
    height: 40px;
    font-size: 14px;
    line-height: 140%;
    text-align: center;

    margin: 0px !important;

}

.react-daterange-picker__calendar-button {
    display: none;
}

.react-daterange-picker__clear-button__icon react-daterange-picker__button__icon {
    display: none;
}

.react-daterange-picker__wrapper {
    background-color: #21222D;
    border: 0px solid rgb(232, 232, 232);

}


.react-daterange-picker__clear-button svg {
    /* display: none; */
    stroke: var(--light-gray) !important;

}

.react-date-picker__inputGroup__divider {
    color: #5F606D;

}

.react-calendar__navigation__label__labelText {
    color: var(--light-gray);
}


.react-calendar__navigation__arrow {
    color: var(--semi-white);
    font-size: 28px;
}

.react-calendar {
    background-color: var(--dark-gray) !important;
    color: white;

    border: 1px solid var(--bg) !important;

    align-items: center;
    /* border-color: hsl(0, 0%, 80%); */
    border-radius: 6px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;

    border: none;
    color: #5F606D;

    margin-right: 5px;
}

.react-daterange-picker__inputGroup__input:invalid {
    background-color: #21222D;
    color: white;
}

.react-calendar__month-view__days__day--weekend {
    color: var(--light-gray);
    text-decoration: none !important;
}

.react-calendar__month-view__weekdays abbr {
    text-decoration: none;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
    color: var(--light-gray);
    text-decoration: none !important;

}

.react-calendar__month-view__days__day--neighboringMonth {
    color: var(--light-gray);

}

.react-calendar__month-view__days__day {
    color: var(--semi-white);

}

.react-calendar__tile--active {
    background-color: var(--accent);
    color: var(--white)
}

.react-calendar__tile:hover {
    background-color: var(--bg) !important;
    color: var(--white)
}

.react-calendar__tile--now {
    background-color: var(--bg) !important;

}


.react-calendar__tile--hover {
    background-color: var(--accent) !important;
    color: var(--white)
}
:root {
    --black: #13151B;
    --bg: #171821;
    --dark-gray: #21222D;
    --gray: #43444D;
    --light-gray: #b5b7c2;
    --semi-white: #9293A0;
    --white: #FFFFFF;
    --accent: #2595FD;
    --green: #26C363;
    --red: #EA5848;

    --chart-orange: #FEB95A;
    --chart-baby-blue: #a9dfd8b8;
    --chart-blue: #2595FD;
    --chart-dark-blue: #235C95;
    --chart-swamp: rgb(169, 223, 216, 0.5);

    --trade-closed: rgba(234, 88, 72, 0.4);
    --trade-open: rgba(38, 195, 99, 0.4);
    --trade-expired: rgba(254, 185, 90, 0.4);
    --trade-assigned: rgba(169, 223, 216, 0.4);

    --trade-stock: rgba(254, 185, 90, 0.4);
    --trade-call: rgba(169, 223, 216, 0.4);
    --trade-put: rgba(37, 149, 253, 0.4);


    --calendarProfit: rgba(38, 195, 99, 0.1);
    --calendarLoss: rgba(234, 88, 72, 0.1);


    --graph-orange: rgba(254, 185, 90, 0.2);
    --graph-orange-bright: rgba(254, 185, 90, 1);

}

[data-theme="light"] {
    --black: #eceae4;
    --bg: #e8e7de;
    --dark-gray: #deddd2;
    --gray: #bcbbb2;
    --light-gray: #4a483d;
    --semi-white: #6d6c5f;
    --white: #000000;
    --accent: #2595FD;
    --green: #126d35;
    --red: #ce2d1b;
    --chart-orange: #FEB95A;
    --chart-baby-blue: #A9DFD8;
    --chart-blue: #2595FD;
    --chart-dark-blue: #235C95;
    --chart-swamp: rgb(169, 223, 216, 0.5);

    --trade-closed: rgba(229, 46, 26, 0.4);
    --trade-open: rgba(38, 195, 99, 0.4);

    --trade-stock: rgba(254, 185, 90, 0.4);
    --trade-call: rgba(169, 223, 216, 0.4);
    --trade-put: rgba(37, 149, 253, 0.4);

    --calendarProfit: rgba(38, 195, 98, 0.661);
    --calendarLoss: rgba(234, 88, 72, 0.595);
}


.design_library_black__1XqRH {
    background-color: #13151B;
    background-color: var(--black);
    width: 40px;
    height: 40px;
    border-radius: 32px;
    margin-right: 10px;
    border: 1.5px solid #FFFFFF;
    border: 1.5px solid var(--white);
}

.design_library_bg__1PKEL {
    background-color: #171821;
    background-color: var(--bg);
    width: 40px;
    height: 40px;
    border-radius: 32px;
    margin-right: 10px;
    border: 1.5px solid #FFFFFF;
    border: 1.5px solid var(--white);
}

.design_library_darkGray__1BNnd {
    background-color: #21222D;
    background-color: var(--dark-gray);
    width: 40px;
    height: 40px;
    border-radius: 32px;
    margin-right: 10px;
    border: 1.5px solid #FFFFFF;
    border: 1.5px solid var(--white);
}

.design_library_gray__1ogq6 {
    background-color: #43444D;
    background-color: var(--gray);
    width: 40px;
    height: 40px;
    border-radius: 32px;
    margin-right: 10px;
    border: 1.5px solid #FFFFFF;
    border: 1.5px solid var(--white);
}

.design_library_lightGray__3Uh_q {
    background-color: #b5b7c2;
    background-color: var(--light-gray);
    width: 40px;
    height: 40px;
    border-radius: 32px;
    margin-right: 10px;
    border: 1.5px solid #FFFFFF;
    border: 1.5px solid var(--white);
}

.design_library_semiWhite__l4Iqo {
    background-color: #9293A0;
    background-color: var(--semi-white);
    width: 40px;
    height: 40px;
    border-radius: 32px;
    margin-right: 10px;
    border: 1.5px solid #FFFFFF;
    border: 1.5px solid var(--white);
}

.design_library_white__2Ws37 {
    background-color: #FFFFFF;
    background-color: var(--white);
    width: 40px;
    height: 40px;
    border-radius: 32px;
    margin-right: 10px;
    border: 1.5px solid #FFFFFF;
    border: 1.5px solid var(--white);
}

.design_library_accent__3KtXl {
    background-color: #2595FD;
    background-color: var(--accent);
    width: 40px;
    height: 40px;
    border-radius: 32px;
    margin-right: 10px;
    border: 1.5px solid #FFFFFF;
    border: 1.5px solid var(--white);
}

.design_library_green__3bVsX {
    background-color: #26C363;
    background-color: var(--green);
    width: 40px;
    height: 40px;
    border-radius: 32px;
    margin-right: 10px;
    border: 1.5px solid #FFFFFF;
    border: 1.5px solid var(--white);
}

.design_library_red__1apMi {
    background-color: #EA5848;
    background-color: var(--red);
    width: 40px;
    height: 40px;
    border-radius: 32px;
    margin-right: 10px;
    border: 1.5px solid #FFFFFF;
    border: 1.5px solid var(--white);
}

/* Panels */

.design_library_background__2eJ3v {
    background-color: #171821;
    background-color: var(--bg);
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}



.design_library_blockOverlayActive__34_uh {
    background-color: #13151B;
    background-color: var(--black);
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3;
}

/* Buttons */

.design_library_customSwitch__20dMp {}


.design_library_customSwitch__20dMp span {
    background-color: #171821 !important;
    background-color: var(--bg) !important;


}

.design_library_customSwitch__20dMp span span {
    background-color: #b5b7c2 !important;
    background-color: var(--light-gray) !important;


}

.design_library_customSwitchActive__1ILhw {}

.design_library_customSwitchActive__1ILhw span {
    background-color: #2595FD !important;
    background-color: var(--accent) !important;
}

.design_library_customSwitchActive__1ILhw span span {
    background-color: #FFFFFF !important;
    background-color: var(--white) !important;

}

.design_library_customSwitch__20dMp[data-checked] span {
    background-color: #2595FD !important;
    background-color: var(--accent) !important;
}

.design_library_customSwitch__20dMp[data-checked] span span {
    background-color: #FFFFFF !important;
    background-color: var(--white) !important;
}



.design_library_button__1lXGY {
    height: 40px;

    background: #21222D;

    background: var(--dark-gray);
    border-radius: 6px;

    /* max-width: 120px; */
}

.design_library_primaryButton__3GunY {
    background: #2595FD !important;
    background: var(--accent) !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0em !important;
    text-align: center !important;


    text-shadow: #171821 0px 0px 10px;


    text-shadow: var(--bg) 0px 0px 10px;

}

.design_library_secondaryButton__3_oy7 {
    color: #2595FD;
    color: var(--accent);
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0em !important;
    text-align: center !important;

    text-shadow: #171821 0px 0px 10px;

    text-shadow: var(--bg) 0px 0px 10px;

}

.design_library_secondaryButton__3_oy7:hover {
    background-color: #43444D;
    background-color: var(--gray);
}

.design_library_negativeButton__2x2wi {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #EA5848;
    color: var(--red);

    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
    letter-spacing: 0em !important;
    text-align: center !important;
}

.design_library_outlineButton__DgNi- {
    font-size: 16px !important;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #b5b7c2;
    color: var(--light-gray);
    background-color: transparent !important;


}

.design_library_outlineButtonActive__B9GBZ {
    font-size: 16px !important;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
    color: var(--white);
    background-color: transparent !important;
    border-bottom: 2px #2595FD solid;
    border-bottom: 2px var(--accent) solid;
    border-radius: 0px !important;
}



.design_library_iconButton__1RRln {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    padding: 10px 12px;


    flex: none;
    order: 0;
    flex-grow: 1;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #2595FD;
    color: var(--accent);
    background: #21222D;
    background: var(--dark-gray);
    border-radius: 6px;
    stroke-width: 1.5px;

}

.design_library_iconButton__1RRln svg {
    fill: #b5b7c2;
    fill: var(--light-gray);
}

.design_library_overRideIconColor__3HmyY {
    background-color: #171821 !important;
    background-color: var(--bg) !important;
}

.design_library_overRideIconColor__3HmyY span {
    padding: 0;
    -webkit-margin-start: 0;
            margin-inline-start: 0;
}

.design_library_overRideIconColor__3HmyY svg {
    stroke: transparent !important;
}

.design_library_overRideIconColorActive__2HEqk {
    background-color: #171821 !important;
    background-color: var(--bg) !important;
}

.design_library_overRideIconColorActive__2HEqk span {
    padding: 0;
    -webkit-margin-start: 0;
            margin-inline-start: 0;
}

.design_library_overRideIconColorActive__2HEqk svg {
    stroke: transparent !important;
}

.design_library_overRideIconColorActive__2HEqk svg path {
    fill: #2595FD !important;
    fill: var(--accent) !important;
}

.design_library_roundButton__21FEq {
    width: 40px;
    height: 40px;
    border-radius: 32px;
    border: 2px solid #21222D;
    border: 2px solid var(--dark-gray);
}

.design_library_roundButton__21FEq:hover {
    background-color: #2595FD !important;
    background-color: var(--accent) !important;
}

.design_library_roundButton__21FEq:hover svg {
    color: #FFFFFF !important;
    color: var(--white) !important;
}

.design_library_activeRoundIcon__FGrsV svg {
    fill: #2595FD !important;
    fill: var(--accent) !important;
}

.design_library_activated__2qqE8 {
    color: #2595FD;
    color: var(--accent);
    stroke: #FFFFFF;
    stroke: var(--white);
    /* background-color: red; */

}

.design_library_activated__2qqE8 svg {
    color: transparent;
    stroke: #2595FD;
    stroke: var(--accent);
    fill: transparent;

    width: 20px;
    height: 20px;
}

.design_library_deactivated__3O_BW {
    color: #FFFFFF;
    color: var(--white);
    stroke: #FFFFFF;
    stroke: var(--white);

    fill: transparent;


}

.design_library_deactivated__3O_BW svg {
    color: transparent;
    stroke: #FFFFFF;
    stroke: var(--white);
    width: 20px;
    height: 20px;

}



.design_library_bellActivated__3kSvp svg {
    color: transparent;
    stroke: #FFFFFF;
    stroke: var(--white);
    fill: #FFFFFF;
    fill: var(--white);
    width: 20px;
    height: 20px;
}

.design_library_bellDeactivated__16s8J svg {
    color: #FFFFFF;
    color: var(--white);
    stroke: #FFFFFF;
    stroke: var(--white);
    fill: #FFFFFF;
    fill: var(--white);
    width: 20px;
    height: 20px;
}

.design_library_roundIcon__1bKh- {
    border-radius: 32px !important;
    background-color: #21222D !important;
    background-color: var(--dark-gray) !important;
}

.design_library_roundIcon__1bKh- svg {
    stroke: transparent;
    fill: #b5b7c2;
    fill: var(--light-gray);
    width: 20px;
    height: 20px;
}

/* Typography */

.design_library_link__emdrs {}

.design_library_link__emdrs:hover {
    cursor: pointer;
}

.design_library_title__1oQr5 {
    /* bold/20px */
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    /* identical to box height, or 28px */

    display: flex;
    align-items: center;

    /* dark/white */

    color: #FFFFFF;

    color: var(--white);

}

.design_library_innerText__1eZwM {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #9293A0;
    color: var(--semi-white);

    text-align: left;
    align-self: flex-start;

}

.design_library_itemHeading__2xmW7 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #FFFFFF;
    color: var(--white);

    text-align: left;
    align-self: flex-start;
}

.design_library_notes__34X1J {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    color: #9293A0;
    color: var(--semi-white);
}

/* Input */

.design_library_dropdown__2tO0r {
    width: 100%;


}

.design_library_dropdown__2tO0r svg {
    stroke-width: 2px;
    stroke: white;
    fill: transparent;
    width: 20px;
    height: 20px;

}

.design_library_darkDropdown__zsbvj {
    /* min-width: 200px; */
    width: 100%;

}

.design_library_dropdownCreatable__16NBY {
    min-width: 120px !important;
    width: 120px !important;
}



.design_library_inputGroup__1-xq- {
    /* padding-bottom: 15px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start !important;

}

.design_library_checkbox__2K11D {
    font-size: 14px !important;
    border: transparent 0;
}



.design_library_textInputFrame__Rzfcp {
    max-width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    color: #b5b7c2;
    color: var(--light-gray);
    background-color: #21222D;
    background-color: var(--dark-gray);
    border-radius: 6px;
    border: transparent;

    z-index: 7;
}


.design_library_textInput__2n0mu {
    color: #FFFFFF;
    color: var(--white);
    margin: 0 !important;
}



.design_library_textInputLeftIcon__3s7Q5 {
    stroke: #9293A0;
    stroke: var(--semi-white);
    fill: transparent;
    stroke-width: 2px;
}

.design_library_textInputLeftIcon__3s7Q5 svg {
    width: 20px;
    height: 20px;
}

.design_library_textInputLeftIconAdditional__2F1qt {
    stroke: #9293A0;
    stroke: var(--semi-white);
    fill: transparent;
    stroke-width: 2px;
    -webkit-transform: translateX(50px);
            transform: translateX(50px);
    color: White;
}


.design_library_showInputElement__Eis_i {}

.design_library_showButton__1AmJA {
    padding: 0 !important;


    background: transparent !important;
    color: #FFFFFF;
    color: var(--white);
    font-size: 14px !important;
}

.design_library_showButton__1AmJA:hover {
    background: transparent !important;
}

/* Structure */

.design_library_tabs__dcN6T {}


.design_library_tabPanels__k-VFc {}

.design_library_tab__2iO8g {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    align-items: center;

    color: #FFFFFF;

    color: var(--white);

    border-bottom: transparent;




}


.design_library_tabList__lwX3N {
    border-bottom: transparent !important;
    /* overflow-y: hidden; */
    overflow-x: scroll;
    padding: 3px;
}


.design_library_tab__2iO8g[aria-selected=true],
.design_library_tab__2iO8g[data-selected] {
    color: #2595FD !important;
    color: var(--accent) !important;
    border-color: #2595FD !important;
    border-color: var(--accent) !important;

}




/* Widgets */

.design_library_widgetPanel__23DYr {
    /* width: 258px; */
    /* height: 145px; */
    height: 100%;
    width: 100%;

    /* dark/dark-gray */

    background: #21222D;

    background: var(--dark-gray);
    border-radius: 12px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;


}

.design_library_widgetPanelNoBackground__Zc47E {
    /* width: 258px; */
    /* height: 145px; */
    height: 100%;
    width: 100%;

    /* dark/dark-gray */

    border-radius: 12px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;


    border:dashed #43444D 2px;


    border:dashed var(--gray) 2px;
}


.design_library_socialSharePanel__Uh_jY {
    /* width: 258px; */
    /* height: 145px; */
    height: 100%;
    width: 100%;

    /* dark/dark-gray */

    background: #21222D;

    background: var(--dark-gray);
    border-radius: 12px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    border-color: #2595FD;
    border-color: var(--accent);
    border-width: 2px;

}


.design_library_widgetPanelWithOverFlow__3C9yb {
    height: 100%;
    width: 100%;
    background: #21222D;
    background: var(--dark-gray);
    border-radius: 12px;
    padding-left: 20px;
    padding-right: 20px;

    overflow-y: scroll;

}

.design_library_widgetHeader__3PDA- {
    height: 36px;

    display: flex;
    justify-content: space-between;

    padding-top: 20px;
}

.design_library_widgetButtonContainer__1uiLi {
    margin-top: 20px;

    max-width: 50px;
    display: flex;
    justify-content: space-around;



    flex: 2 1;
}

.design_library_widgetTitle__3paAD {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    line-height: 140%;
    display: flex;
    align-items: center;
    color: #9293A0;
    color: var(--semi-white);

    flex: 6 1;
}

.design_library_infoIcon__2LoaM {

    padding: 0;
    margin: 0;

    width: 24px;
    height: 24px;

    fill: #43444D;

    fill: var(--gray);

    stroke: transparent;
    stroke-width: 1.5px;
}

.design_library_dragHandleIcon__xyQnA {
    width: 20px !important;
    height: 20px !important;
    fill: #9293A0;
    fill: var(--semi-white);
    position: relative;
    top: 0px;
    stroke: #b5b7c2;
    stroke: var(--light-gray);
    stroke-width: 2.5px;
    fill: #b5b7c2;
    fill: var(--light-gray);

    z-index: 30;
}

.design_library_statNumber__37w_o {

    padding-top: 12px;
    /* bold/32px */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    /* font-size: 100%; */
    line-height: 140%;
    /* identical to box height, or 45px */

    display: flex;
    align-items: center;

    /* dark/white */

    color: #FFFFFF;

    color: var(--white);

}

.design_library_statChangePositive__3Di0L {

    /* medium/14px */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    display: flex;
    align-items: center;

    /* status/green */

    color: #26C363;

    color: var(--green);

}

.design_library_statChangePositiveLarge__2WKmq {

    /* medium/14px */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    /* identical to box height, or 20px */

    display: flex;
    align-items: center;

    /* status/green */

    color: #26C363;

    color: var(--green);

}

.design_library_statChangeNeutral__3K2GD {
    /* medium/14px */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    display: flex;
    align-items: center;

    /* status/green */

    color: #9293A0;

    color: var(--semi-white);

}

.design_library_negativeIcon__2i-S9 {
    fill: #EA5848;
    fill: var(--red)
}

.design_library_positiveIcon__1muH4 {
    fill: #26C363;
    fill: var(--green)
}

.design_library_statChangeNegative__cQzsX {

    /* medium/14px */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    display: flex;
    align-items: center;

    /* status/green */

    color: #EA5848;

    color: var(--red)
}

.design_library_statChangeNegativeLarge__27DI4 {

    /* medium/14px */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    /* identical to box height, or 20px */

    display: flex;
    align-items: center;

    /* status/green */

    color: #EA5848;

    color: var(--red)
}


/* Sidebar */

.design_library_sideBarIcon__1vMDp {
    width: 43px;
    height: 43px;
    background: #21222D !important;
    background: var(--dark-gray) !important;
    box-shadow: 8.05051px 24.1515px 89.4501px -11.6285px rgba(22, 52, 80, 0.1);
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    transition: 0.4s all;
}

.design_library_sideBarIcon__1vMDp:hover svg {
    stroke: #2595FD !important;
    stroke: var(--accent) !important;
    fill: #2595FD !important;
    fill: var(--accent) !important;
}


.design_library_sideBarIconShort__2CfSf {
    width: 43px;
    height: 43px;
    background: #21222D !important;
    background: var(--dark-gray) !important;
    box-shadow: 8.05051px 24.1515px 89.4501px -11.6285px rgba(22, 52, 80, 0.1);
    border-radius: 6px;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}



.design_library_sideBarText__2Lf4z {

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;

    padding-left: 12px;
    /* or 22px */

    display: flex;
    align-items: center;

    /* dark/white */

    color: #FFFFFF;

    color: var(--white);


    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 0px 12px;


    min-width: 200px;
}

.design_library_sideBarSubtitle__3Lgj7 {

    /* title */


    position: static;
    width: 63px;
    height: 20px;
    left: 0px;
    top: 24px;

    /* regular/14px */

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    /* identical to box height, or 20px */

    display: flex;
    align-items: center;

    /* dark/semi-white */

    color: #9293A0;

    color: var(--semi-white);


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 4px 0px;
}

.design_library_sideBarTextInactive__1VMPE {
    min-width: 200px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;

    padding-left: 12px;
    /* or 22px */

    display: flex;
    align-items: center;

    /* dark/white */

    color: #b5b7c2;

    color: var(--light-gray);


    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 0px 12px;

}

.design_library_plusIcon__DBwR5 {
    min-width: 44px !important;
    min-height: 44px !important;
    background: #21222D !important;
    background: var(--dark-gray) !important;
    width: 44px !important;
    height: 44px !important;
    border-radius: 32px !important;
    color: #FFFFFF !important;
    color: var(--white) !important;
    font-size: 24px !important;
}

.design_library_plusIcon__DBwR5 svg {
    stroke: #9293A0;
    stroke: var(--semi-white);
    fill: #9293A0;
    fill: var(--semi-white);
    stroke-width: 1.5px;

}

.design_library_sideBarRow__3wLD0 {
    padding-left: 16px;
    border-left: 4px solid transparent;
    cursor: pointer;


    padding-top: 10px;
    padding-bottom: 10px;

    margin-top: 0px !important;
}

.design_library_sideBarMenuItemRow__p_6oA {
    padding-left: 16px;
    border-left: 4px solid transparent;
    cursor: pointer;


    padding-top: 6px;
    padding-bottom: 6px;



}

.design_library_sideBarMenuItemRowShort__3k4N1 {
    padding-left: 16px;
    cursor: pointer;


    padding-top: 6px;
    padding-bottom: 6px;



}

.design_library_sideBarMenuItemRowActive__3Fogg {
    padding-left: 16px;
    border-left: 4px solid transparent;
    cursor: pointer;


    padding-top: 6px;
    padding-bottom: 6px;

    border-left: 4px solid #2595FD !important;

    border-left: 4px solid var(--accent) !important;
    border-radius: 0px 2px 2px 0px !important;
    border-top-left-radius: 2px !important;
}

.design_library_sideBarRowActive__1U0Sb {
    border-left: 4px solid #2595FD;
    border-left: 4px solid var(--accent);
    border-radius: 0px 2px 2px 0px;
    border-top-left-radius: 2px;
    padding-top: 12px;
    padding-bottom: 12px;

    margin-top: 0px !important;

}

.design_library_sideBarRowTextContainer__2i_5p {
    text-align: left;
}

.design_library_sideBarProfileIcon__1m0LP {


    width: 44px !important;
    height: 44px !important;
    min-width: 44px !important;
    min-height: 44px !important;
    background: #2595FD !important;
    background: var(--accent) !important;
    border-radius: 32px !important;
    color: #FFFFFF !important;
    color: var(--white) !important;
    font-size: 24px !important;
}



.design_library_sideBarProfileIconInactive__XeNPb {
    transition: 2.4s all;

    border: solid 2px transparent;

    min-width: 44px !important;
    min-height: 44px !important;
    width: 44px !important;
    height: 44px !important;
    background: #21222D !important;
    background: var(--dark-gray) !important;
    border-radius: 32px !important;
    color: #9293A0 !important;
    color: var(--semi-white) !important;
    font-size: 24px !important;
}

.design_library_sideBarProfileIconInactive__XeNPb:hover {
    border: solid 2px #2595FD;
    border: solid 2px var(--accent);
    background-color: #2595FD !important;
    background-color: var(--accent) !important;
    color: white !important;
    opacity: 0.5;
}

.design_library_plusIcon__DBwR5:hover {
    border: solid 2px #2595FD;
    border: solid 2px var(--accent);
    color: white !important;
}



/* Tables */

.design_library_tablesFrame__tN8b1 {
    /* margin-top: 20px; */



}




.design_library_tablesHeader__2xUSb {}

.design_library_tablesRow__2N778 {

    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 140% !important;
    /* identical to box height, or 20px */

    /* dark/white */

    color: #FFFFFF !important;

    color: var(--white) !important;



}

.design_library_tablesRow__2N778:hover {
    background-color: #21222D;
    background-color: var(--dark-gray);
    cursor: pointer;

}

.design_library_tablesHead__aq5cf {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 140% !important;

    color: #b5b7c2 !important;

    color: var(--light-gray) !important;
    border-color: transparent !important;

    padding-left: 0 !important;
    margin-left: 0 !important;
}

.design_library_customTbody__3D1f1 {}

.design_library_customTd__19zCl {
    border-color: transparent !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
    padding-right: 0 !important;
    margin-right: 0 !important;
}

/* Modal */


.design_library_modal__3Iz0- {
    /* background-color: var(--bg) !important; */

}

.design_library_customDrawer__1SgRW {}

.design_library_customDrawerContent__3z1bx {
    background-color: #21222D !important;
    background-color: var(--dark-gray) !important;
}

.design_library_closeButton__2VLbt {
    stroke: #21222D;
    stroke: var(--dark-gray)
}



.design_library_modalContent__2zQmt {
    background-color: #171821 !important;
    background-color: var(--bg) !important;


    margin: 0;

}

.design_library_basicModal__I0iEX {
    background-color: transparent !important;
    border: 0 !important;
    box-shadow: none !important;
}

.design_library_Glass__38nhH {
    overflow: hidden !important;
    margin: 0 !important;
    padding: 5px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: #FFFFFF !important;
    color: var(--white) !important;
    text-align: center !important;





}


.design_library_Glass__38nhH:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;



    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 4px !important;
    -webkit-transform: 2s all !important;
            transform: 2s all !important;
}

.design_library_GlassTitle__1_lb3 {

    letter-spacing: 0.01em;

    /* dark/white */

    color: #FFFFFF;

    color: var(--white);


    opacity: 0.8;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;

    font-size: 10px;
}

.design_library_GlassText__20FSe {

    color: #FFFFFF;

    color: var(--white);

    text-align: left;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    font-size: 14px;

}


.design_library_CustomToolTip__3vpAd {
    overflow: hidden !important;
    margin: 0 !important;
    padding: 5px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    color: #FFFFFF !important;
    color: var(--white) !important;
    text-align: center !important;
    background-color: transparent !important;
}

.design_library_CustomToolTip__3vpAd:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;

    color: #FFFFFF !important;

    color: var(--white) !important;

    background: rgba(157, 157, 157, 0.6);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    -webkit-backdrop-filter: blur(12px);
            backdrop-filter: blur(12px);
    border-radius: 4px;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    -webkit-transform: 2s all;
            transform: 2s all;
}

.design_library_CustomToolTipLeftAligned__2YSNM {
    margin: 0 !important;
    padding: 5px !important;
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    color: #FFFFFF !important;
    color: var(--white) !important;
    background-color: transparent !important;
    text-align: left !important;
}

.design_library_CustomToolTipLeftAligned__2YSNM:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
    -webkit-backdrop-filter: blur(12px);
            backdrop-filter: blur(12px);
    border-radius: 4px;
    -webkit-transform: rotate(-180deg);
            transform: rotate(-180deg);
    -webkit-transform: 2s all;
            transform: 2s all;
}

/* Charts */


.design_library_chartContainer__C_j_M {
    height: 100%;
    width: 100%;
}

/* Extra */

.design_library_tag__3qyy_ {
    padding: 8px 16px !important;


    /* dark/bg */

    background: #171821 !important;

    background: var(--bg) !important;
    -webkit-backdrop-filter: blur(8px) !important;
            backdrop-filter: blur(8px) !important;
    /* Note: backdrop-filter has minimal browser support */

    border-radius: 40px !important;
}

.design_library_dark__1h2mZ {
    background-color: #171821 !important;
    background-color: var(--bg) !important;
    padding: 12px 20px !important;
    font-size: 16px !important;
}

.design_library_fat__3qYFu {
    padding: 12px 20px !important;
    border-radius: 6px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: white;
    font-size: 16px !important;
}

.design_library_customSecondary__qQlte {
    padding: 12px 20px !important;
    border-radius: 6px !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    color: white;
    background-color: #171821 !important;
    background-color: var(--bg) !important;

    font-size: 16px !important;
}

.design_library_textInputDark__3_rKA {
    background-color: #171821 !important;
    background-color: var(--bg) !important;
    margin: 0 !important;

}



.design_library_textArea__1HNoD {
    background-color: #171821 !important;
    background-color: var(--bg) !important;
}

.design_library_darkGrayBg__jshtO {
    background-color: #21222D !important;
    background-color: var(--dark-gray) !important;
}

.design_library_tradeRowHeaderText__2Tltf {
    color: #b5b7c2;
    color: var(--light-gray);
    font-weight: 400;
    font-size: 12px;
}


.design_library_insetTabsTall__1eqgI {}

.design_library_insetTabs__12t0K {}

.design_library_insetTab__2o8GJ {
    /* dark/dark-gray */

    border-radius: 6px;

    flex: none;
    align-self: stretch;
    flex-grow: 1;
    margin: 0px 1px;
    border-color: transparent;
    color: #9293A0;
    color: var(--semi-white);
    border: 0 !important;
    background: #21222D;
    background: var(--dark-gray);


}

.design_library_insetTab__2o8GJ[data-selected],
.design_library_insetTab__2o8GJ[aria-selected=true] {
    color: #FFFFFF !important;
    color: var(--white) !important;
    border: 0 !important;
    background: #171821;
    background: var(--bg);

    border: 1px solid #43444D !important;

    border: 1px solid var(--gray) !important;

}



.design_library_insetTabPanels__3CC_z {}

.design_library_insetTabList__1cr0k {
    background: #21222D;
    background: var(--dark-gray);
    padding: 4px;
    padding-bottom: 6px;
    border-radius: 8px;
    border: 0 !important;
    width: 100%;
}

.design_library_firstBreadcrumb___3QSm {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #b5b7c2 !important;
    color: var(--light-gray) !important;
}

.design_library_breadcrumb__2vKDS {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #FFFFFF !important;
    color: var(--white) !important;
}


.design_library_redBackgroundSVG__pvDUT {
    background-color: #EA5848 !important;
    background-color: var(--red) !important;
}

.design_library_redBackgroundSVG__pvDUT svg {
    fill: #FFFFFF !important;
    fill: var(--white) !important;
}

::-webkit-input-placeholder {
    color: #9293A0 !important;
    color: var(--semi-white) !important
}

:-ms-input-placeholder {
    color: #9293A0 !important;
    color: var(--semi-white) !important
}

::placeholder {
    color: #9293A0 !important;
    color: var(--semi-white) !important
}

.design_library_closeIcon__2dmOL {
    cursor: pointer;
}
h1,
h2,
h3,
button,
input,
p {
    font-family: 'Inter', sans-serif !important;
}

* {
    font-family: 'Inter', sans-serif !important;

}


.ag-icon {
    font-family: var(--ag-icon-font-family) !important;
}

.ag-input-field-input {
    font-family: var(--ag-input-font-family) !important;
}

.ag-checkbox-input-wrapper {
    font-family: var(--ag-icon-font-family) !important;

}


input:hover {
    border-color: var(--white) !important;
    border: 1px solid;
}

.inputBorder {
    border: 1px solid var(--gray) !important;
}

#tooltip {
    position: absolute;
    left: 50px;
    top: 50px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 12px;

    display: inline-block;
}

#tooltip.right {
    margin-left: 5px;
}

#tooltip.left {
    margin-left: -5px;
}

#tooltip.top {
    margin-top: -5px;
}

#tooltip.bottom {
    margin-top: 5px;
}

#tooltip .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000;
}

#tooltip.right .tooltip-arrow {
    top: 50%;
    left: auto;
    margin-left: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000;
}



#tooltip.top .tooltip-arrow {
    top: auto;
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
}

#tooltip.left .tooltip-arrow {
    top: 50%;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #000;
    right: -5px;
    left: auto;
}

#tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000;
}

#tooltip .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-right-color: transparent;
    border-style: solid;
}

#tooltip .tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 4px;
}

#tooltip.on {
    display: block;
}

#tooltip.off {
    display: none;
}


.checkbox__control {

    background-color: var(--bg) !important;

}

.chakra-checkbox__control {
    margin-top: 5px;
    align-self: flex-start;
}

.chakra-checkbox__label {
    font-size: 14px !important;
}

.chakra-checkbox__control[data-checked] {
    background: var(--accent) !important;
    border: var(--accent) !important;
    color: white !important;
}

.chakra-checkbox {
    align-items: flex-start;

}

.customDatePicker {
    background-color: var(--bg);


}


.react-datepicker-popper {
    z-index: 9 !important;



}

.react-datepicker__header {
    background-color: var(--bg) !important;
    border-bottom: 0 !important;
}

.react-datepicker__month-container {
    background-color: var(--bg) !important;
    border: solid var(--light-gray) 1px !important;
    border-radius: 6px;

}

.react-datepicker__current-month {
    color: var(--light-gray) !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 140% !important;
    width: 100% !important;


}

.react-datepicker__day-name,
.react-datepicker__day--outside-month {
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 140% !important;


}


.react-datepicker__day-name {
    color: var(--light-gray) !important;
    height: 40px !important;
    width: 40px !important;
    padding-top: 20px !important;
}




.react-datepicker__day {
    color: var(--semi-white) !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    height: 40px !important;
    width: 40px !important;

    vertical-align: middle !important;
    line-height: 40px !important;
    text-align: center !important;

}


.react-datepicker__day--outside-month {
    height: 40px !important;
    width: 40px !important;
    color: var(--light-gray) !important;
}


.react-datepicker__day:hover {
    background-color: var(--black) !important;
    color: var(--white) !important;

}



.layerZero {
    z-index: 0 !important;
}

.react-datepicker input {
    /* border: solid 1px var(--gray) !important; */
    /*  */
}

.react-datepicker__day--selected {
    background-color: var(--accent) !important;
    border-radius: 0px !important;
    color: var(--white) !important;

}

div[role=radiogroup] {
    background-color: var(--dark-gray);
    padding: 4px;
    border-radius: 8px;
    color: var(--white);

}

div[role=radiogroup] div {
    background-color: var(--dark-gray);
    border: 0;
    padding: 4px;
    color: var(--white);
    box-shadow: none !important;

}

div[role=radiogroup] div[data-checked] {
    background-color: var(--bg);
    border: 1px solid var(--gray);
    color: var(--white);

}


.react-datepicker {
    background-color: var(--dark-gray) !important;
    height: 40px;
    width: 100%;
    padding: 10px 12px !important;
    color: var(--light-gray);
    font-size: 16px !important;
    border: 0 !important;
}

.react-datepicker {
    color: var(--white) !important;
}



.custom-toggle.react-toggle--checked .react-toggle-track {
    background-color: var(--accent);
}

.custom-toggle.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--accent);
}
.DARK {
    background-color: #1a202c;

}

.DARK h1 {
    color: white;
}

.DARK ul {
    color: white;
}

.DARK li {
    color: white;
}

.DARK h2 {
    color: white;
}

.DARK h3 {
    color: white;
}

.DARK p {
    color: white;
}

.DARK input {
    color: white;
}

.DARK a {
    color: rgb(15, 171, 255) !important;
}

.LIGHT {
    background-color: white;

}

.LIGHT p {
    color: black;
}

.LIGHT li {
    color: black;
}

.LIGHT h2 {
    color: black;
}

.LIGHT h1 {
    color: black;
}

.LIGHT h3 {
    color: black;
}

.LIGHT a {
    color: rgb(0, 115, 177);
}

.aspectRatioSet {
    object-fit: cover;
    width: 100%;
    height: 480px;
    object-position: 0;
}

.articleArrow svg {

    height: 50px;
    width: 50px;
    vertical-align: middle;

}

.articleArrow2 svg {

    height: 60px;
    width: 60px;
    vertical-align: middle;

    -webkit-transform: translateY(4px);

            transform: translateY(4px);
}

.articlePages {
    font-size: 20px;
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
}

.mde-text,
.react-mde-tabbed-layout {
    background: var(--dark-gray);
    height: 1000px !important;
    border: 0 !important;
}

.mde-header {
    background: var(--dark-gray) !important
}

.svg-icon {
    color: var(--white);
}
.barX ::-webkit-scrollbar {
  width: 0px;
}

.markdown-body {
  max-width: 1400px;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.markdown-body .octicon {
  display: inline-block;
  fill: currentColor;
  vertical-align: text-bottom;
}

.markdown-body .anchor {
  float: left;
  line-height: 1;
  margin-left: -20px;
  padding-right: 4px;
}

.markdown-body .anchor:focus {
  outline: none;
}

.markdown-body h1 .octicon-link,
.markdown-body h2 .octicon-link,
.markdown-body h3 .octicon-link,
.markdown-body h4 .octicon-link,
.markdown-body h5 .octicon-link,
.markdown-body h6 .octicon-link {
  vertical-align: middle;
  visibility: hidden;
}

.markdown-body h1:hover .anchor,
.markdown-body h2:hover .anchor,
.markdown-body h3:hover .anchor,
.markdown-body h4:hover .anchor,
.markdown-body h5:hover .anchor,
.markdown-body h6:hover .anchor {
  text-decoration: none;
}

.markdown-body h1:hover .anchor .octicon-link,
.markdown-body h2:hover .anchor .octicon-link,
.markdown-body h3:hover .anchor .octicon-link,
.markdown-body h4:hover .anchor .octicon-link,
.markdown-body h5:hover .anchor .octicon-link,
.markdown-body h6:hover .anchor .octicon-link {
  visibility: visible;
}

.markdown-body h1:hover .anchor .octicon-link:before,
.markdown-body h2:hover .anchor .octicon-link:before,
.markdown-body h3:hover .anchor .octicon-link:before,
.markdown-body h4:hover .anchor .octicon-link:before,
.markdown-body h5:hover .anchor .octicon-link:before,
.markdown-body h6:hover .anchor .octicon-link:before {
  width: 16px;
  height: 16px;
  content: ' ';
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' version='1.1' width='16' height='16' aria-hidden='true'%3E%3Cpath fill-rule='evenodd' d='M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z'%3E%3C/path%3E%3C/svg%3E");
}

.markdown-body {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
  font-size: 20px;
  line-height: 1.5;
  word-wrap: break-word;
}

.markdown-body details {
  display: block;
}

.markdown-body summary {
  display: list-item;
}

.markdown-body a {
  background-color: transparent;
  background-color: initial;
}

.markdown-body a:active,
.markdown-body a:hover {
  outline-width: 0;
}

.markdown-body strong {
  font-weight: inherit;
  font-weight: bolder;
}

.markdown-body h1 {
  font-size: 2em;
  margin: .67em 0;
}

.markdown-body img {
  border-style: none;
  /* max-width: 1200px; */
  width: 100%;
  border-radius: 6px;
  ;
}

.markdown-body code,
.markdown-body kbd,
.markdown-body pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

.markdown-body hr {
  box-sizing: content-box;
  box-sizing: initial;
  height: 0;
  overflow: visible;
}

.markdown-body input {
  font: inherit;
  margin: 0;
}

.markdown-body input {
  overflow: visible;
}

.markdown-body [type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

.markdown-body * {
  box-sizing: border-box;
}

.markdown-body input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.markdown-body a {
  text-decoration: none;
}

.markdown-body a:hover {
  text-decoration: underline;
}

.markdown-body strong {
  font-weight: 600;
}

.markdown-body hr {
  height: 0;
  margin: 15px 0;
  overflow: hidden;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #dfe2e5;
}

.markdown-body hr:after,
.markdown-body hr:before {
  display: table;
  content: "";
}

.markdown-body hr:after {
  clear: both;
}

.markdown-body table {
  border-spacing: 0;
  border-collapse: collapse;
}

.markdown-body td,
.markdown-body th {
  padding: 0;
}

.markdown-body details summary {
  cursor: pointer;
}

.markdown-body kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  line-height: 10px;
  vertical-align: middle;
  border-radius: 3px;
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.markdown-body h1 {
  font-size: 32px;
}

.markdown-body h1,
.markdown-body h2 {
  font-weight: 600;
}

.markdown-body h2 {
  font-size: 24px;
}

.markdown-body h3 {
  font-size: 20px;
}

.markdown-body h3,
.markdown-body h4 {
  font-weight: 600;
}

.markdown-body h4 {
  font-size: 16px;
}

.markdown-body h5 {
  font-size: 14px;
}

.markdown-body h5,
.markdown-body h6 {
  font-weight: 600;
}

.markdown-body h6 {
  font-size: 12px;
}

.markdown-body p {
  margin-top: 0;
  margin-bottom: 10px;
}

.markdown-body blockquote {
  margin: 0;
}

.markdown-body ol,
.markdown-body ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.markdown-body ol ol,
.markdown-body ul ol {
  list-style-type: lower-roman;
}

.markdown-body ol ol ol,
.markdown-body ol ul ol,
.markdown-body ul ol ol,
.markdown-body ul ul ol {
  list-style-type: lower-alpha;
}

.markdown-body dd {
  margin-left: 0;
}

.markdown-body code,
.markdown-body pre {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 12px;
}

.markdown-body pre {
  margin-top: 0;
  margin-bottom: 0;
}

.markdown-body input::-webkit-inner-spin-button,
.markdown-body input::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
}

.markdown-body :checked+.radio-label {
  position: relative;
  z-index: 1;
}

.markdown-body .border {}

.markdown-body .border-0 {
  border: 0 !important;
}

.markdown-body .border-bottom {}

.markdown-body .rounded-1 {
  border-radius: 3px !important;
}

.markdown-body .bg-white {}

.markdown-body .bg-gray-light {}

.markdown-body .text-gray-light {}

.markdown-body .mb-0 {
  margin-bottom: 0 !important;
}

.markdown-body .my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.markdown-body .pl-0 {
  padding-left: 0 !important;
}

.markdown-body .py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.markdown-body .pl-1 {
  padding-left: 4px !important;
}

.markdown-body .pl-2 {
  padding-left: 8px !important;
}

.markdown-body .py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.markdown-body .pl-3,
.markdown-body .px-3 {
  padding-left: 16px !important;
}

.markdown-body .px-3 {
  padding-right: 16px !important;
}

.markdown-body .pl-4 {
  padding-left: 24px !important;
}

.markdown-body .pl-5 {
  padding-left: 32px !important;
}

.markdown-body .pl-6 {
  padding-left: 40px !important;
}

.markdown-body .f6 {
  font-size: 12px !important;
}

.markdown-body .lh-condensed {
  line-height: 1.25 !important;
}

.markdown-body .text-bold {
  font-weight: 600 !important;
}

/*   
  .markdown-body .pl-c {
  }
  
  .markdown-body .pl-c1,
  .markdown-body .pl-s .pl-v {
  }
  
  .markdown-body .pl-e,
  .markdown-body .pl-en {
  }
  
  .markdown-body .pl-s .pl-s1,
  .markdown-body .pl-smi {
  }
  
  .markdown-body .pl-ent {
  }
  
  .markdown-body .pl-k {
  }
  
  .markdown-body .pl-pds,
  .markdown-body .pl-s,
  .markdown-body .pl-s .pl-pse .pl-s1,
  .markdown-body .pl-sr,
  .markdown-body .pl-sr .pl-cce,
  .markdown-body .pl-sr .pl-sra,
  .markdown-body .pl-sr .pl-sre {
    color: #032f62;
  }
  
  .markdown-body .pl-smw,
  .markdown-body .pl-v {
    color: #e36209;
  }
  
  .markdown-body .pl-bu {
    color: #b31d28;
  }
  
  .markdown-body .pl-ii {
    color: #fafbfc;
    background-color: #b31d28;
  }
  
  .markdown-body .pl-c2 {
    color: #fafbfc;
    background-color: #d73a49;
  }
  
  .markdown-body .pl-c2:before {
    content: "^M";
  }
  
  .markdown-body .pl-sr .pl-cce {
    font-weight: 700;
    color: #22863a;
  }
  
  .markdown-body .pl-ml {
    color: #735c0f;
  }
  
  .markdown-body .pl-mh,
  .markdown-body .pl-mh .pl-en,
  .markdown-body .pl-ms {
    font-weight: 700;
    color: #005cc5;
  }
  
  .markdown-body .pl-mi {
    font-style: italic;
    color: #24292e;
  }
  
  .markdown-body .pl-mb {
    font-weight: 700;
    color: #24292e;
  }
  
  .markdown-body .pl-md {
    color: #b31d28;
    background-color: #ffeef0;
  }
  
  .markdown-body .pl-mi1 {
    color: #22863a;
    background-color: #f0fff4;
  }
  
  .markdown-body .pl-mc {
    color: #e36209;
    background-color: #ffebda;
  }
  
  .markdown-body .pl-mi2 {
    color: #f6f8fa;
    background-color: #005cc5;
  }
  
  .markdown-body .pl-mdr {
    font-weight: 700;
    color: #6f42c1;
  }
  
  .markdown-body .pl-ba {
    color: #586069;
  }
  
  .markdown-body .pl-sg {
    color: #959da5;
  }
  
  .markdown-body .pl-corl {
    text-decoration: underline;
    color: #032f62;
  } */

.markdown-body .mb-0 {
  margin-bottom: 0 !important;
}

.markdown-body .my-2 {
  margin-bottom: 8px !important;
}

.markdown-body .my-2 {
  margin-top: 8px !important;
}

.markdown-body .pl-0 {
  padding-left: 0 !important;
}

.markdown-body .py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.markdown-body .pl-1 {
  padding-left: 4px !important;
}

.markdown-body .pl-2 {
  padding-left: 8px !important;
}

.markdown-body .py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.markdown-body .pl-3 {
  padding-left: 16px !important;
}

.markdown-body .pl-4 {
  padding-left: 24px !important;
}

.markdown-body .pl-5 {
  padding-left: 32px !important;
}

.markdown-body .pl-6 {
  padding-left: 40px !important;
}

.markdown-body .pl-7 {
  padding-left: 48px !important;
}

.markdown-body .pl-8 {
  padding-left: 64px !important;
}

.markdown-body .pl-9 {
  padding-left: 80px !important;
}

.markdown-body .pl-10 {
  padding-left: 96px !important;
}

.markdown-body .pl-11 {
  padding-left: 112px !important;
}

.markdown-body .pl-12 {
  padding-left: 128px !important;
}

.markdown-body hr {
  border-bottom-color: #eee;
}

.markdown-body kbd {
  display: inline-block;
  padding: 3px 5px;
  font: 11px SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  line-height: 10px;
  vertical-align: middle;
  border: 1px solid #d1d5da;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #d1d5da;
}

.markdown-body:after,
.markdown-body:before {
  display: table;
  content: "";
}

.markdown-body:after {
  clear: both;
}

.markdown-body>:first-child {
  margin-top: 0 !important;
}

.markdown-body>:last-child {
  margin-bottom: 0 !important;
}

.markdown-body a:not([href]) {
  color: inherit;
  text-decoration: none;
}

.markdown-body blockquote,
.markdown-body details,
.markdown-body dl,
.markdown-body ol,
.markdown-body p,
.markdown-body pre,
.markdown-body table,
.markdown-body ul {
  margin-top: 0;
  margin-bottom: 16px;
}

.markdown-body hr {
  height: .25em;
  padding: 0;
  margin: 24px 0;
  border: 0;
}

.markdown-body blockquote {
  padding: 0 1em;
  color: #6a737d;
  border-left: .25em solid #dfe2e5;
}

.markdown-body blockquote>:first-child {
  margin-top: 0;
}

.markdown-body blockquote>:last-child {
  margin-bottom: 0;
}

.markdown-body h1,
.markdown-body h2,
.markdown-body h3,
.markdown-body h4,
.markdown-body h5,
.markdown-body h6 {
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 600;
  line-height: 1.25;
}

.markdown-body h1 {
  font-size: 2em;
}

.markdown-body h1,
.markdown-body h2 {
  padding-bottom: .3em;
}

.markdown-body h2 {
  font-size: 1.5em;
}

.markdown-body h3 {
  font-size: 1.25em;
}

.markdown-body h4 {
  font-size: 1em;
}

.markdown-body h5 {
  font-size: .875em;
}

.markdown-body h6 {
  font-size: .85em;
  color: #6a737d;
}

.markdown-body ol,
.markdown-body ul {
  padding-left: 2em;
}

.customBreadcrumb ol {
  padding-left: 0 !important;
}

.markdown-body ol ol,
.markdown-body ol ul,
.markdown-body ul ol,
.markdown-body ul ul {
  margin-top: 0;
  margin-bottom: 0;
}

.markdown-body li {
  word-wrap: break-all;
}

.markdown-body li>p {
  margin-top: 16px;
}

.markdown-body li+li {
  margin-top: .25em;
}

.markdown-body dl {
  padding: 0;
}

.markdown-body dl dt {
  padding: 0;
  margin-top: 16px;
  font-size: 1em;
  font-style: italic;
  font-weight: 600;
}

.markdown-body dl dd {
  padding: 0 16px;
  margin-bottom: 16px;
}

.markdown-body table {
  display: block;
  width: 100%;
  overflow: auto;
}

.markdown-body table th {
  font-weight: 600;
}

.markdown-body table td,
.markdown-body table th {
  padding: 6px 13px;
}

.markdown-body table tr {
  border-top: 1px solid #c6cbd1;
}

.markdown-body table tr:nth-child(2n) {}

.markdown-body img {
  width: 100%;
  /* max-width: 1000px; */
  border-radius: 6px;

  box-sizing: content-box;

  box-sizing: initial;
}

.markdown-body img[align=right] {
  padding-left: 20px;
}

.markdown-body img[align=left] {
  padding-right: 20px;
}

.markdown-body code {
  padding: .2em .4em;
  margin: 0;
  font-size: 85%;
  background-color: rgba(27, 31, 35, .05);
  border-radius: 3px;
}

.markdown-body pre {
  word-wrap: normal;
}

.markdown-body pre>code {
  padding: 0;
  margin: 0;
  font-size: 100%;
  word-break: normal;
  white-space: pre;
  background: transparent;
  border: 0;
}

.markdown-body .highlight {
  margin-bottom: 16px;
}

.markdown-body .highlight pre {
  margin-bottom: 0;
  word-break: normal;
}

.markdown-body .highlight pre,
.markdown-body pre {
  padding: 16px;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  border-radius: 3px;
}

.markdown-body pre code {
  display: inline;
  max-width: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  word-wrap: normal;
  background-color: transparent;
  background-color: initial;
  border: 0;
}

.markdown-body .commit-tease-sha {
  display: inline-block;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 90%;
  color: #444d56;
}

.markdown-body .full-commit .btn-outline:not(:disabled):hover {}

.markdown-body .blob-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
}

.markdown-body .blob-wrapper-embedded {
  max-height: 240px;
  overflow-y: auto;
}

.markdown-body .blob-num {
  width: 1%;
  min-width: 50px;
  padding-right: 10px;
  padding-left: 10px;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 12px;
  line-height: 20px;
  color: rgba(27, 31, 35, .3);
  text-align: right;
  white-space: nowrap;
  vertical-align: top;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.markdown-body .blob-num:hover {
  color: rgba(27, 31, 35, .6);
}

.markdown-body .blob-num:before {
  content: attr(data-line-number);
}

.markdown-body .blob-code {
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  line-height: 20px;
  vertical-align: top;
}

.markdown-body .blob-code-inner {
  overflow: visible;
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
  font-size: 12px;
  word-wrap: normal;
  white-space: pre;
}

.markdown-body .pl-token.active,
.markdown-body .pl-token:hover {
  cursor: pointer;
  background: #ffea7f;
}

.markdown-body .tab-size[data-tab-size="1"] {
  tab-size: 1;
}

.markdown-body .tab-size[data-tab-size="2"] {
  tab-size: 2;
}

.markdown-body .tab-size[data-tab-size="3"] {
  tab-size: 3;
}

.markdown-body .tab-size[data-tab-size="4"] {
  tab-size: 4;
}

.markdown-body .tab-size[data-tab-size="5"] {
  tab-size: 5;
}

.markdown-body .tab-size[data-tab-size="6"] {
  tab-size: 6;
}

.markdown-body .tab-size[data-tab-size="7"] {
  tab-size: 7;
}

.markdown-body .tab-size[data-tab-size="8"] {
  tab-size: 8;
}

.markdown-body .tab-size[data-tab-size="9"] {
  tab-size: 9;
}

.markdown-body .tab-size[data-tab-size="10"] {
  tab-size: 10;
}

.markdown-body .tab-size[data-tab-size="11"] {
  tab-size: 11;
}

.markdown-body .tab-size[data-tab-size="12"] {
  tab-size: 12;
}

.markdown-body .task-list-item {
  list-style-type: none;
}

.markdown-body .task-list-item+.task-list-item {
  margin-top: 3px;
}

.markdown-body .task-list-item input {
  margin: 0 .2em .25em -1.6em;
  vertical-align: middle;
}
.Pricing_pricingCardBox__2SN6U {
    min-height: 228px;
    background: var(--bg);
    border-radius: 12px;

    min-width: 370px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;


    margin-top: 20px;
    margin-bottom: 20px;
}


.Pricing_priceBox__2Zuic {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.Pricing_currency__1ZG0D {
    font-size: 18px;
    font-weight: 700;
    padding-top: 12px;
    padding-right: 5px;
    text-align: center;

}

.Pricing_price__2prLm {

    font-weight: 700;
    font-size: 34px;
    line-height: 140%;

    color: var(--white);
    text-align: center;


}

.Pricing_frequencyBox__1PS7e {

    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    display: flex;
    align-items: center;
    text-align: center;

    /* dark/semi-white */

    color: var(--semi-white);
}

.Pricing_featuresText__aC9tf {

    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* or 25px */

    display: flex;
    align-items: center;
    text-align: center;


    /* dark/semi-white */

    color: var(--semi-white);

    text-decoration: underline;
}

.Pricing_highlight__2xMAd {
    background: var(--accent);

    font-weight: 700;
    font-size: 18px;
    line-height: 140%;
    /* or 25px */

    display: flex;
    align-items: center;
    margin: auto;
    justify-content: center;
    text-align: center;
    /* dark/white */

    height: 43px;
    color: var(--white);


    border-top-left-radius: 12px;
    border-top-right-radius: 12px;


}

.Pricing_pricingCardBoxWithHighlight__2Am7W {
    min-width: 370px;
    min-height: 228px;
    background: var(--bg);
    border-radius: 12px;
    -webkit-margin-start: 0px !important;
            margin-inline-start: 0px !important;

    margin-top: 20px;
    margin-bottom: 20px;


}

.Pricing_center__2-OBi {
    justify-content: center;

    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.Pricing_priceBoxWithHighlight__22WiO {
    min-width: 370px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    margin-top: 8%;
}
.plusOne {
    position: absolute;
    top: 0px;
    left: 0px;
    -webkit-transform: translateX(-330%) translateY(20%) !important;
            transform: translateX(-330%) translateY(20%) !important;

}

.plusTwo {
    position: absolute;
    top: 0px;
    left: 0px;
    -webkit-transform: translateX(-330%) translateY(280%) !important;
            transform: translateX(-330%) translateY(280%) !important;

}

.plusThree {
    position: absolute;
    top: 0px;
    left: 0px;
    -webkit-transform: translateX(250%) translateY(60%) !important;
            transform: translateX(250%) translateY(60%) !important;

}

.plusFour {
    position: absolute;
    top: 0px;
    left: 0px;
    -webkit-transform: translateX(200%) translateY(200%) !important;
            transform: translateX(200%) translateY(200%) !important;

}

.graphicHolder {
    width: 0;
    height: 0;
    position: relative;
    top: 50%;
    left: 50%;
}


@media only screen and (max-width: 1200px) {
    .graphicHolder {
        display: none;
    }
}
.Footer_footer__3dvK2 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
